/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  PaginationItem,
  CardFooter,
  Pagination,
  PaginationLink,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

import Header from "components/Headers/Header.js";

const Index = (props) => {
  const [activeNav, setActiveNav] = useState(1);
  const [chartExample1Data, setChartExample1Data] = useState("data1");

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase ls-1 mb-1">
                      Overview
                    </h6>
                    <h2 className="mb-0">Sales value</h2>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 1,
                          })}
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 1)}
                        >
                          <span className="d-none d-md-block">Month</span>
                          <span className="d-md-none">M</span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 2,
                          })}
                          data-toggle="tab"
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 2)}
                        >
                          <span className="d-none d-md-block">Week</span>
                          <span className="d-md-none">W</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  <Line
                    data={chartExample1[chartExample1Data]}
                    options={chartExample1.options}
                    getDatasetAtEvent={(e) => console.log(e)}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          {/* <Col xl="4">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Performance
                    </h6>
                    <h2 className="mb-0">Total orders</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Bar
                    data={chartExample2.data}
                    options={chartExample2.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col> */}
        </Row>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            {/* <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Sales by Store Offline</h3>
                  </div>
                </Row>
              </CardHeader>
              <zing-grid
                viewport-stop
                group-by="Brand"
                data='[{
                  "Brand": "Rolex",
                  "Store": "Rolex-REX",
                  "Today Sales": "1",
                  "MTD": "4309",
                  "Vs MTD LY": "89",
                  "Vs Target": "93"
                },
                {
                  "Brand": "Rolex",
                  "Store": "Rolex - TTP",
                  "Today Sales": "725",
                  "MTD": "4509",
                  "Vs MTD LY": "89",
                  "Vs Target": "98"
                },
                {
                  "Brand": "Cartier",
                  "Store": "Cartier REX",
                  "Today Sales": "450",
                  "MTD": "4309",
                  "Vs MTD LY": "89",
                  "Vs Target": "93"
                },
                {
                  "Brand": "Cartier",
                  "Store": "Cartier TPP",
                  "Today Sales": "525",
                  "MTD": "1309",
                  "Vs MTD LY": "89",
                  "Vs Target": "93"
                },
                {
                  "Brand": "Burberry",
                  "Store": "Burberry REX",
                  "Today Sales": "425",
                  "MTD": "6309",
                  "Vs MTD LY": "89",
                  "Vs Target": "93"
                },
                {
                  "Brand": "Burberry",
                  "Store": "Burberry TPP",
                  "Today Sales": "325",
                  "MTD": "2309",
                  "Vs MTD LY": "80",
                  "Vs Target": "80"
                },
                {
                  "Brand": "D&G",
                  "Store": "D&G REX",
                  "Today Sales": "125",
                  "MTD": "4309",
                  "Vs MTD LY": "80",
                  "Vs Target": "93"
                },
                {
                  "Brand": "D&G",
                  "Store": "D&G TPP",
                  "Today Sales": "125",
                  "MTD": "2309",
                  "Vs MTD LY": "89",
                  "Vs Target": "93"
                }
              ]'
              >
                <zg-colgroup>
                  <zg-column type="row-group" index="Brand,Store" ></zg-column>
                  <zg-column index="Today Sales" group-head-cell="[[sum]]" type="number" ></zg-column>
                  <zg-column index="MTD" group-head-cell="[[sum]]" type="number" ></zg-column>
                  <zg-column index="vs MTD LY" group-head-cell="[[sum]]" type="number" ></zg-column>
                  <zg-column index="vs Target" group-head-cell="[[sum]]" type="number"  ></zg-column>
                </zg-colgroup>
              </zing-grid>
            </Card> */}
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Sales by OFFLINE CHANNEL</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" style={{ tableLayout: 'fixed' }} responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" style={{ whiteSpace: 'normal', paddingLeft: 0, paddingRight: 0, width: '20%' }}><div className="text-center">Store</div></th>
                    <th scope="col" style={{ whiteSpace: 'normal', paddingLeft: 0, paddingRight: 0 }}><div className="text-center">Today Sales</div></th>
                    <th scope="col" style={{ whiteSpace: 'normal', paddingLeft: 0, paddingRight: 0 }}><div className="text-center">MTD</div></th>
                    <th scope="col" style={{ whiteSpace: 'normal', paddingLeft: 0, paddingRight: 0 }}><div className="text-center">Vs MTD LY</div></th>
                    <th scope="col" style={{ whiteSpace: 'normal', paddingLeft: 0, paddingRight: 0 }}><div className="text-center">Vs Target</div></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-1 py-1 align-items-center text-center">
                        <div className="ms-0 text-center">
                          <h6 className="ms-0">TUMI- REX</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="ms-0">500</h6>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="ms-0">3,900</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="text-success font-weight-bolder">89.9%</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="text-success font-weight-bolder">83.44%</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="mb-0">TUMI - TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">900</h6>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">4,000</h6>
                      </div>
                    </td>
                    <td className="align-middle text-xs" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="text-success font-weight-bolder">70.22%</h6>
                      </div>
                    </td>
                    <td className="align-middle text-xs" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="text-success font-weight-bolder">73.44%</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="mb-0">Monblanc- REX</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">100</h6>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">2,700</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">23.44%</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">23.44%</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="mb-0">Monblanc- TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">562</h6>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">3,960</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="text-success font-weight-bolder">72.14%</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="text-success font-weight-bolder">63.44%</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="mb-0">Burberry REX</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">562</h6>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">3,960</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">32.14%</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">30.44%</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="mb-0">Burberry TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">462</h6>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">4,960</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">42.14%</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">53.44%</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="mb-0">D&G  REX</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">268</h6>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">13,960</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">52.14%</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">63.44%</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="mb-0">D&G TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">362</h6>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">13,000</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">32.14%</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">23.44%</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="mb-0">D&G TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">362</h6>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">13,000</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">32.14%</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">23.44%</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="mb-0">D&G TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">362</h6>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">13,000</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">32.14%</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">23.44%</h6>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
          {/* <Col xl="4">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Social traffic</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                    >
                      See all
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Referral</th>
                    <th scope="col">Visitors</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Facebook</th>
                    <td>1,480</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">60%</span>
                        <div>
                          <Progress
                            max="100"
                            value="60"
                            barClassName="bg-gradient-danger"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Facebook</th>
                    <td>5,480</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">70%</span>
                        <div>
                          <Progress
                            max="100"
                            value="70"
                            barClassName="bg-gradient-success"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Google</th>
                    <td>4,807</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">80%</span>
                        <div>
                          <Progress max="100" value="80" />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Instagram</th>
                    <td>3,678</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">75%</span>
                        <div>
                          <Progress
                            max="100"
                            value="75"
                            barClassName="bg-gradient-info"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">twitter</th>
                    <td>2,645</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">30%</span>
                        <div>
                          <Progress
                            max="100"
                            value="30"
                            barClassName="bg-gradient-warning"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col> */}
        </Row>
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Sales by ONLINE CHANNEL</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" style={{ tableLayout: 'fixed' }} responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" style={{ whiteSpace: 'normal', paddingLeft: 0, paddingRight: 0, width: '20%' }}><div className="text-center">Store</div></th>
                    <th scope="col" style={{ whiteSpace: 'normal', paddingLeft: 0, paddingRight: 0 }}><div className="text-center">Today Sales</div></th>
                    <th scope="col" style={{ whiteSpace: 'normal', paddingLeft: 0, paddingRight: 0 }}><div className="text-center">MTD</div></th>
                    <th scope="col" style={{ whiteSpace: 'normal', paddingLeft: 0, paddingRight: 0 }}><div className="text-center">Vs MTD LY</div></th>
                    <th scope="col" style={{ whiteSpace: 'normal', paddingLeft: 0, paddingRight: 0 }}><div className="text-center">Vs Target</div></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-1 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="ms-0">TUMI- REX</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="ms-0">500</h6>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="ms-0">3,900</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="text-success font-weight-bolder">89.9%</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="text-success font-weight-bolder">83.44%</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="mb-0">TUMI - TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">900</h6>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">4,000</h6>
                      </div>
                    </td>
                    <td className="align-middle text-xs" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="text-success font-weight-bolder">70.22%</h6>
                      </div>
                    </td>
                    <td className="align-middle text-xs" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="text-success font-weight-bolder">73.44%</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="mb-0">Monblanc- REX</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">100</h6>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">2,700</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">23.44%</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">23.44%</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="mb-0">Monblanc- TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">562</h6>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">3,960</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="text-success font-weight-bolder">72.14%</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="text-success font-weight-bolder">63.44%</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="mb-0">Burberry REX</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">562</h6>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">3,960</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">32.14%</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">30.44%</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="mb-0">Burberry TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">462</h6>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">4,960</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">42.14%</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">53.44%</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="mb-0">D&G  REX</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">268</h6>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">13,960</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">52.14%</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">63.44%</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="mb-0">D&G TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">362</h6>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">13,000</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">32.14%</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">23.44%</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="mb-0">D&G TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">362</h6>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">13,000</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">32.14%</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">23.44%</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="mb-0">D&G TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">362</h6>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="mb-0">13,000</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">32.14%</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm" style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <div className="col text-center">
                        <h6 className="mb-0">23.44%</h6>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
              {/* <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className="active">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        1
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        2 <span className="sr-only">(current)</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        3
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter> */}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Index;
