/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";

// reactstrap components
import { Card, CardFooter, CardHeader, Container, Pagination, PaginationItem, PaginationLink, Row, Table } from "reactstrap";

// core components
import Header from "components/Headers/Header.js";
import { Chart } from "chart.js";

const Maps = () => {
  useEffect(() => {
    var xValues = ["watch& Jw", "clothing ", "footwea", "Bags", "other"];
    var yValues = [55, 49, 44, 24, 15];
    var barColors = [
      "#059BFF",
      "#FFCD56",
      "#FF6384",
      "#FF9F40",
      "#4BC0C0"
    ];

    new Chart("pieChart", {
      type: "pie",
      data: {
        labels: xValues,
        datasets: [{
          backgroundColor: barColors,
          data: yValues
        }]
      },
      options: {
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Inventory value by Category'
          }
        }
      }
    });
  }, [])

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow border-0" style={{ width: '100%', maxWidth: 700 }}>
              <canvas id="pieChart"></canvas>
            </Card>
          </div>
        </Row>
        <Row className="pt-5">
          <div className="col">
            <Card className="shadow border-0">
              <CardHeader className="border-0">
                <h3 className="mb-0">SOH by Store</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" style={{ tableLayout: 'fixed' }} responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" style={{ whiteSpace: 'normal', paddingLeft: 0, paddingRight: 0, width: '20%' }}><div className="text-center">Store</div></th>
                    <th scope="col" style={{ whiteSpace: 'normal', paddingLeft: 0, paddingRight: 0 }}><div className="text-center">Qty (today)</div></th>
                    <th scope="col" style={{ whiteSpace: 'normal', paddingLeft: 0, paddingRight: 0 }}><div className="text-center">Inv Amt Landed Cost</div></th>
                    <th scope="col" style={{ whiteSpace: 'normal', paddingLeft: 0, paddingRight: 0 }}><div className="text-center">Inv Amt Retail Price</div></th>
                    <th scope="col" style={{ whiteSpace: 'normal', paddingLeft: 0, paddingRight: 0 }}><div className="text-center">MOH</div></th>
                  </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-sm mb-0">Rolex- REX</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">79</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">39.000</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">69.000</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">25</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-sm mb-0">Rolex- TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">29</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">19,000</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">25,000</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">25</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-sm mb-0">Buberry- REX</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">229</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">2,119</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">4,567</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">25</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-sm mb-0">Buberry- TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">223</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">1,221</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">2,559</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">25</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-sm mb-0">Versace- REX</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">456</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">3,111</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">5,136</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">25</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-sm mb-0">Versace- TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">118</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">1,567</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">3,111</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">25</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-sm mb-0">Versace- TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">118</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">1,567</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">3,111</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">25</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-sm mb-0">Versace- TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">118</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">1,567</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">3,111</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">25</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-sm mb-0">Versace- TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">118</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">1,567</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">3,111</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">25</h6>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-sm mb-0">Versace- TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">118</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">1,567</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">3,111</h6>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">25</h6>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Maps;
