/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";

const Tables = () => {
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Sales by Store Offline</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" style={{ tableLayout: 'fixed' }} responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" style={{ whiteSpace: 'normal', paddingLeft: 0, paddingRight: 0, width: '20%' }}><div className="text-center">Store</div></th>
                    <th scope="col" style={{ whiteSpace: 'normal', paddingLeft: 0, paddingRight: 0 }}><div className="text-center">APT MTD <br /> (vs LY)</div></th>
                    <th scope="col" style={{ whiteSpace: 'normal', paddingLeft: 0, paddingRight: 0 }}><div className="text-center">UPT MTD <br /> (vs LY)</div></th>
                    <th scope="col" style={{ whiteSpace: 'normal', paddingLeft: 0, paddingRight: 0 }}><div className="text-center">Qty Trans <br /> (vs LY)</div></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-sm mb-0">Rolex- REX</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">879</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">105%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-success text-sm ">+119</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">2</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">102%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-success text-sm ">+1</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">20</h6>
                          <p className="mb-0">
                            <span className=" text-sm mb-0">95%</span>
                          </p>
                          <p className="mb-0">
                            <span className=" text-warning text-sm ">-3</span>
                          </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-sm mb-0">Rolex- TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">899</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">110%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-success text-sm ">+299%</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">2</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">110%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-success text-sm ">+1</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">25</h6>
                          <p className="mb-0">
                            <span className=" text-sm mb-0">105%</span>
                          </p>
                          <p className="mb-0">
                            <span className=" text-success text-sm ">+5</span>
                          </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-sm mb-0">Buberry- REX</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">259</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">115%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-success text-sm ">+15</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">3</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">120%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-success text-sm ">+1</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">33</h6>
                          <p className="mb-0">
                            <span className=" text-sm mb-0">105%</span>
                          </p>
                          <p className="mb-0">
                            <span className=" text-success text-sm ">+5</span>
                          </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-sm mb-0">Buberry- TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">189</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">85%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-warning text-sm ">-35</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">3</h6>
                        <p className="mb-0">
                          <span className="text-warning text-sm mb-0">90%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-warning text-sm ">-1</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">22</h6>
                          <p className="mb-0">
                            <span className=" ext-success text-sm mb-0">95%</span>
                          </p>
                          <p className="mb-0">
                            <span className=" text-success text-sm ">-5</span>
                          </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-sm mb-0">Versace- REX</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">159</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">115%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-success text-sm ">+59</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">3</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">110%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-success text-sm ">+1</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">35</h6>
                          <p className="mb-0">
                            <span className=" text-sm mb-0">105%</span>
                          </p>
                          <p className="mb-0">
                            <span className=" text-success text-sm ">+3</span>
                          </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-sm mb-0">Versace- TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">190</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">85%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-warning text-sm ">-25</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">3</h6>
                        <p className="mb-0">
                          <span className="text-warning text-sm mb-0">90%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-warning text-sm ">-1</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">22</h6>
                          <p className="mb-0">
                            <span className=" ext-success text-sm mb-0">95%</span>
                          </p>
                          <p className="mb-0">
                            <span className=" text-success text-sm ">-5</span>
                          </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-sm mb-0">Versace- TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">190</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">85%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-warning text-sm ">-25</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">3</h6>
                        <p className="mb-0">
                          <span className="text-warning text-sm mb-0">90%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-warning text-sm ">-1</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">22</h6>
                          <p className="mb-0">
                            <span className=" ext-success text-sm mb-0">95%</span>
                          </p>
                          <p className="mb-0">
                            <span className=" text-success text-sm ">-5</span>
                          </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-sm mb-0">Versace- TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">190</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">85%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-warning text-sm ">-25</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">3</h6>
                        <p className="mb-0">
                          <span className="text-warning text-sm mb-0">90%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-warning text-sm ">-1</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">22</h6>
                          <p className="mb-0">
                            <span className=" ext-success text-sm mb-0">95%</span>
                          </p>
                          <p className="mb-0">
                            <span className=" text-success text-sm ">-5</span>
                          </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-sm mb-0">Versace- TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">190</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">85%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-warning text-sm ">-25</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">3</h6>
                        <p className="mb-0">
                          <span className="text-warning text-sm mb-0">90%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-warning text-sm ">-1</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">22</h6>
                          <p className="mb-0">
                            <span className=" ext-success text-sm mb-0">95%</span>
                          </p>
                          <p className="mb-0">
                            <span className=" text-success text-sm ">-5</span>
                          </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-sm mb-0">Versace- TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">190</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">85%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-warning text-sm ">-25</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">3</h6>
                        <p className="mb-0">
                          <span className="text-warning text-sm mb-0">90%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-warning text-sm ">-1</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">22</h6>
                          <p className="mb-0">
                            <span className=" ext-success text-sm mb-0">95%</span>
                          </p>
                          <p className="mb-0">
                            <span className=" text-success text-sm ">-5</span>
                          </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
        <Row className="mt-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Sales by ONLINE</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" style={{ tableLayout: 'fixed' }} responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" style={{ whiteSpace: 'normal', paddingLeft: 0, paddingRight: 0, width: '20%' }}><div className="text-center">Store</div></th>
                    <th scope="col" style={{ whiteSpace: 'normal', paddingLeft: 0, paddingRight: 0 }}><div className="text-center">APT MTD <br />(vs LY)</div></th>
                    <th scope="col" style={{ whiteSpace: 'normal', paddingLeft: 0, paddingRight: 0 }}><div className="text-center">UPT MTD <br />(vs LY)</div></th>
                    <th scope="col" style={{ whiteSpace: 'normal', paddingLeft: 0, paddingRight: 0 }}><div className="text-center">Qty Trans <br />(vs LY)</div></th>
                  </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-xs ms-0">TUMI- REX</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">200</h6>
                        <p className="mb-0">
                          <span className="text-success text-xs mb-0">105%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-success text-xs ">+51</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">2</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">100%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-success text-sm ">+0</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">20</h6>
                          <p className="mb-0">
                            <span className=" text-sm mb-0">95%</span>
                          </p>
                          <p className="mb-0">
                            <span className=" text-warning text-sm ">-5</span>
                          </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-xs mb-0">TUMI- TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">200</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">110%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-success text-sm ">+10</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">2</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">100%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-success text-sm ">+0</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">25</h6>
                          <p className="mb-0">
                            <span className=" text-sm mb-0">105%</span>
                          </p>
                          <p className="mb-0">
                            <span className=" text-success text-sm ">+5</span>
                          </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-xs mb-0">Buberry- REX</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">79</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">115%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-success text-sm ">+11</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">3</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">110%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-success text-sm ">+1</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">33</h6>
                          <p className="mb-0">
                            <span className=" text-sm mb-0">105%</span>
                          </p>
                          <p className="mb-0">
                            <span className=" text-success text-sm ">+5</span>
                          </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-xs ">Monblanc- TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">129</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">85%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-warning text-sm ">-15</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">3</h6>
                        <p className="mb-0">
                          <span className="text-warning text-sm mb-0">90%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-warning text-sm ">-1</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">22</h6>
                          <p className="mb-0">
                            <span className=" ext-success text-sm mb-0">95%</span>
                          </p>
                          <p className="mb-0">
                            <span className=" text-success text-sm ">-5</span>
                          </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-sm mb-0">Versace- REX</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">159</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">115%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-success text-sm ">+59</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">3</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">100%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-success text-sm ">+1</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">33</h6>
                          <p className="mb-0">
                            <span className=" text-sm mb-0">105%</span>
                          </p>
                          <p className="mb-0">
                            <span className=" text-success text-sm ">+5</span>
                          </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-xs mb-0">Versace- TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">70</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">85%</span>
                          vs ly
                        </p>
                        <p className="mb-0">
                          <span className="text-warning text-sm ">-15</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">3</h6>
                        <p className="mb-0">
                          <span className="text-warning text-sm mb-0">90%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-warning text-sm ">-1</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">22</h6>
                          <p className="mb-0">
                            <span className=" ext-success text-sm mb-0">95%</span>
                          </p>
                          <p className="mb-0">
                            <span className=" text-success text-sm ">-5</span>
                          </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-xs mb-0">Versace- TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">70</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">85%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-warning text-sm ">-15</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">3</h6>
                        <p className="mb-0">
                          <span className="text-warning text-sm mb-0">90%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-warning text-sm ">-1</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">22</h6>
                          <p className="mb-0">
                            <span className=" ext-success text-sm mb-0">95%</span>
                          </p>
                          <p className="mb-0">
                            <span className=" text-success text-sm ">-5</span>
                          </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-xs mb-0">Versace- TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">70</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">85%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-warning text-sm ">-15</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">3</h6>
                        <p className="mb-0">
                          <span className="text-warning text-sm mb-0">90%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-warning text-sm ">-1</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">22</h6>
                          <p className="mb-0">
                            <span className=" ext-success text-sm mb-0">95%</span>
                          </p>
                          <p className="mb-0">
                            <span className=" text-success text-sm ">-5</span>
                          </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-xs mb-0">Versace- TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">70</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">85%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-warning text-sm ">-15</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">3</h6>
                        <p className="mb-0">
                          <span className="text-warning text-sm mb-0">90%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-warning text-sm ">-1</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">22</h6>
                          <p className="mb-0">
                            <span className=" ext-success text-sm mb-0">95%</span>
                          </p>
                          <p className="mb-0">
                            <span className=" text-success text-sm ">-5</span>
                          </p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-20" style={{ paddingLeft: 0, paddingRight: 0, whiteSpace: 'normal', width: '20%' }}>
                      <div className="d-flex px-2 py-1 align-items-center text-center">
                        <div className="ms-0">
                          <h6 className="text-xs mb-0">Versace- TTP</h6>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="text-center">
                        <h6 className="text-sm mb-0">70</h6>
                        <p className="mb-0">
                          <span className="text-success text-sm mb-0">85%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-warning text-sm ">-15</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                        <h6 className="text-sm mb-0">3</h6>
                        <p className="mb-0">
                          <span className="text-warning text-sm mb-0">90%</span>
                        </p>
                        <p className="mb-0">
                          <span className="text-warning text-sm ">-1</span>
                        </p>
                      </div>
                    </td>
                    <td className="align-middle text-sm">
                      <div className="col text-center">
                          <h6 className="text-sm mb-0">22</h6>
                          <p className="mb-0">
                            <span className=" ext-success text-sm mb-0">95%</span>
                          </p>
                          <p className="mb-0">
                            <span className=" text-success text-sm ">-5</span>
                          </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Tables;
